$mobile: "only screen and (max-width: 768px)";
$tablet: "only screen and (min-width: 769px) and (max-width: 1024px)";
$desktop: "only screen and (min-width: 1025px)";

.timeline {
  margin-top: 20px;
  margin-bottom: 20px;

  &__event {
    display: flex;
    
    align-items: center;

    &:first-child {
      .timeline__event__date.left::after {
        content: '';
        border-top: 2px solid #C3A596;
        width: 10px;
        position: absolute;
        top: 0;right: -6px;
      }
    }

    &:last-child {
      .timeline__event__date.left::after {
        content: '';
        border-top: 2px solid #C3A596;
        width: 10px;
        position: absolute;
        bottom: 0;right: -6px;
      }

      .timeline__event__date.right::after {
        content: '';
        border-top: 2px solid #C3A596;
        width: 10px;
        position: absolute;
        bottom: 0;left: -6px;
      }
    }
    
    &__date, &__description {
      @media #{$desktop} {
        flex: 1;
      }

      @media #{$tablet} {
        flex: 1;
      }
      min-height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 20px;
      padding-bottom: 30px;
    }

    &__description {
      font-family: 'Anton', sans-serif;
      @media #{$mobile} {
        flex: 3;
      }
    }

    &__date {
      @media #{$mobile} {
        flex: 1;
        font-size: 20px;
      }
      @media #{$desktop} {
        font-size: 48px;
      }

      @media #{$tablet} {
        font-size: 36px;
      }
      font-weight: bold;
      color: #C3A596;
      font-family: 'Alex Brush', cursive;
      position: relative;
      align-self: stretch;

      &.left {
        border-right: 2px solid #C3A596;


        .circle {

          @media #{$mobile} {
            right: calc(0px - 10px/2 - 1px);

          }
          @media #{$desktop} {
            right: calc(0px - 21px/2 - 1px);
          }
          @media #{$tablet} {
            right: calc(0px - 21px/2 - 1px);
          }
        }
      }

      &.right {
        border-left: 2px solid #C3A596;

        
        .circle {

          @media #{$mobile} {
            left: calc(0px - 10px/2 - 1px);

          }
          @media #{$desktop} {
            left: calc(0px - 21px/2 - 1px);
          }
          @media #{$tablet} {
            left: calc(0px - 21px/2 - 1px);
          }
        }
      }

      .circle {
        position: absolute;
        
        @media #{$mobile} {
          width: 10px;
          height: 10px;
        }
        @media #{$desktop} {
          width: 21px;
          height: 21px;
        }
        @media #{$tablet} {
          width: 21px;
          height: 21px;
        }
        border-radius: 50%;
        background-color: var(--contrast-color);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        border: 2px solid #C3A596;
      }
    }
    
  }

  .timeline-image {
    position: absolute;
    top: 0;
    right: 0;
    object-fit: cover;
    border: 10px solid var(--contrast-color);
    border-radius: 5px;
    height: 100%;
    width: 100%;
    
    &:first-child {
      // z-index: -1;
    }

    &+.timeline-image  {
      transform: rotate(-9deg);
      
      // z-index: -1;
    }
    

    &__wrapper {
      position: relative;

      @media #{$mobile} {
        width: 200px;
        height: 200px;
      }

      @media #{$desktop} {
        width: 250px;
        height: 250px;
      }
      @media #{$tablet} {
        width: 250px;
        height: 250px;
      }
    }
  }

  
}
