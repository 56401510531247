.chatter-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;


  .message-wrapper {
    // min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    padding: 32px;
    flex-grow: 1;
    align-self: stretch;

    .message {
      color: rgb(75 85 99 / 1);
      font-style: italic;
      font-size: 24px;
      margin-bottom: 16px;

      &.sm {
        font-size: 16px;
      }
    }

    .sender {
      color: rgb(29 78 216 / 1);

      &::first-letter {
        text-transform: uppercase;
      }
    }


  }

  .sender-image {
    img {
      width: 300px;
      
      border-radius: 10px;
      margin-bottom: 30px;
    }
  }
}