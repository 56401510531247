

// media query for mobile devices
$mobile: "only screen and (max-width: 768px)";

// media query for tablet
$tablet: "only screen and (min-width: 769px) and (max-width: 1024px)";

$desktop: "only screen and (min-width: 1025px)";

@media #{$mobile} {
  .countdown-timer {
    font-size: 32px;
    gap: 4px;

    .time-prefix, .time-suffix {
      font-size: 20px;
      width: 28px;
      height: 28px;
      border-radius: 4px;
    }

    &__time {
      width: 48px;
      border-radius: 8px;

      &.d-day {
        width: 96px;
        // font-size: ;
      }
    }

    // .d-day .time-label {
    //   bottom: -5px;
    //   left: 12px;
    //   font-weight: bold;
    //   font-size: 24px;
    // }

    .time-label {
      bottom: -16px;
      left: 8px;
      font-size: 20px;
    }
  }
}

@media #{$tablet} {
  .countdown-timer {
    font-size: 64px;
    gap: 16px;

    .time-prefix, .time-suffix {
      font-size: 28px;
      width: 44px;
      height: 44px;
      border-radius: 8px;
    }

    &__time {
      width: 96px;
      border-radius: 16px;

      &.d-day {
        width: 168px;
      }
    }

    // .d-day .time-label {
    //   bottom: -10px;
    //   left: 21px;
    // }

    .time-label {
      bottom: -24px;
      left: 18px;
      font-size: 36px;

      
    }
  }
}

@media #{$desktop} {
  .countdown-timer {
    font-size: 96px;
    gap: 10px;

    .time-prefix, .time-suffix {
      font-size: 32px;
      width: 48px;
      height: 48px;
      border-radius: 10px;
    }

    &__time {
      width: 144px;
      border-radius: 24px;

      &.d-day {
        width: 256px;
        // font-size: ;
      }

      // &.d-day {
      //   // width: 192px;
      // }
    }

    // .d-day .time-label {
    //   bottom: -2px;
    //   left: 39px;
    //   font-weight: bold;
    //   // font-size: 48px;
    // }

    .time-label {
      bottom: -24px;
      left: 42px;
      font-size: 32px;
    }
  }
}
.countdown-timer {
  display: flex;
  font-family: "Anton", sans-serif;
  font-weight: bold;
  color: white;

  &__time {
    text-align: center;
    background-color: #6a614d;
    position: relative;
  }

  .time-label {
    position: absolute;

    font-family: "Alex Brush", cursive;
  }

  .time-prefix, .time-suffix {
    background-color: #6a614d;
    text-align: center;
    align-self: center;
  }
}