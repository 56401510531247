@import '~bootstrap/scss/bootstrap';
@import './theme.scss';

$mobile: "only screen and (max-width: 768px)";

// media query for tablet
$tablet: "only screen and (min-width: 769px) and (max-width: 1024px)";

$desktop: "only screen and (min-width: 1025px)";

.App {
  text-align: center;

  font-family: 'Poppins';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn {
  border: 0;
  box-shadow: none;
  background-color: var(--accent-color);
  border-color: var(--accent-color);
  padding: 8px 20px 10px 20px;
  border-radius: 7px;
  color: var(--contrast-color);
  transition: 0.3s;
}


.hero-header {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;

  &__title {
    text-align: left;
    color: var(--contrast-color);
    font-weight: bold;
    font-family: "Poppins";
  }
}

.header .logo h1.sitename {
  // text-transform: uppercase;

  @media #{$mobile} {
    font-size: 24px;
  }
  @media #{$tablet} {
    font-size: 36px;
  }
  @media #{$desktop} {
    font-size: 48px;
  }
  font-family: "Anton", cursive;
}

.hero.section {
  background-color: transparent;

  video {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    max-height: 2000px;
  }
}

.features.section {
  background-color: #F5F5F5;;
}

@media #{$mobile} {
  .hero-header__title {
    font-size: 24px;
  }

  .hero {
    min-height: 40vh;

    .container {
      align-self: flex-start;
      padding-top: 50px;
    }
  }

  .hero.section {
  
    video {
      // top: -125px;

      width: 100%;
      height: 54vh;
    }
  }
}

@media #{$tablet} {
  .hero-header__title {
    font-size: 40px;
  }

  .hero {
    min-height: 50vh;

    .container {
      align-self: flex-start;
      padding-top: 50px;
    }
  }

  .hero.section {
  
    video {
      // top: -280px;
      width: 100%;
      height: 52vh;
    }
  }
}

@media #{$desktop} {
  .hero-header__title {
    font-size: 36px;
    font-weight: bold;
  }

  .hero {
    min-height: 75vh;

    .container {
      align-self: flex-start;
    }

    video {
      // top: -280px;
      width: 100%;
      height: 100vh;
    }
  }
}
